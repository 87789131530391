#traceability-page {
    margin-bottom: -80px;

    h1 { color: $primary; }

    section#header {
        // background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 67.19%, rgba(255, 255, 255, 0.47) 76.56%, #FFFFFF 93.75%), linear-gradient(180deg, #EEE1BE 0%, #CEC2A1 11.98%, #B9A66D 77.6%), #B8BF23;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 67.19%, rgba(255, 255, 255, 0.47) 76.56%, #FFFFFF 93.75%), linear-gradient(180deg, #F1DEA9 0%, #DAC690 11.98%, #C7AE67 77.6%), #B9A66D;
        padding: 100px;

        #logo-circle { 
            width: 100%;
            position: relative;
            top: -40px;

            #circle {
                $size: 60vw;
                width: $size;
                height: $size;
                
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                
                background: #fff;
                box-shadow: 0px 11px 21px rgba(0, 0, 0, 0.11), inset 0px 10px 63px rgba(0, 0, 0, 0.21), inset -18px -18px 30px #FFFFFF;
                border-radius: 100%;
                
                img { 
                    width: 80vw; 
                    
                    position: relative;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    section.rounded-page {
        position: relative;
        top: -80px;
        
        section#cards {
            // ! DEBUG MODE
            $debug: false;
            
            >article {
                position: relative;
                z-index: 1;
            }
    
            article {
                $title-padding: 10px;
                $sizes: (30vh, 10vh, calc(25vh - #{$title-padding} * 2), 35vh);
    
                background-color: #fff;
    
                section.image { 
                    @if $debug { border:1px solid blue; }
    
                    height: nth($sizes, 1);
                    background-repeat: no-repeat;
                    background-position: top center;
                    background-size: cover;
                }
    
                section.title, section.description, section.button {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
    
                section.title {
                    @if $debug { border:1px solid violet; }
    
                    height: nth($sizes, 2);
                    padding: $title-padding 0;
    
                    h1 { 
                        text-align: center;
                        @include open-sans-font;
                        font-weight: 800;
                        font-size: 1.3em;
                        margin: 0;
                        padding: 0;
                    }
                }
    
                section.description {
                    @if $debug { border:1px solid green; }
    
                    height: nth($sizes, 3);
                    font-size: .9em;
                    line-height: 22px;
                    color: #000;
    
                    em { 
                        color: $primary; 
                        font-style: normal;
                    }
    
                    a { color: $primary; }
                }
    
                section.button {
                    @if $debug {border:1px solid red; }
    
                    height: nth($sizes, 4);

                    .cover-image {
                        width: 100%;
                        text-align: center;
                        img { height: nth($sizes, 4) - 10vh; }
                    }
                }
            }
    
        }

        section#blockchain {
            margin: 20px 0;
    
            p { 
                font-size: 0.7em;
                span { color: $primary; }
            }
        
            img { width: 100%; }
    
            .verifica {
                text-align: center;
                margin-top: 20px;
            }
        }

        section#last-page {
            text-align: center;
            margin-top: 50px;
            margin-bottom: 100px;
    
            img { width: 100%; }
            h3 { 
                font-weight: 400; 
                margin-top: 0;
            }
            ul {
                list-style: none;
                padding: 15px 0;
                margin-top: 50px;
                background: linear-gradient(180deg, #E2CF9B 0%, #B9A66D 100%);
                border-radius: 34px;
                color: #fff;
    
                li {
                    margin: 40px 0;
                    img { width: 30vw; }
                    div {
                        margin-top: 20px;
                        font-weight: 800;
                    }
                }
            }
    
            p { 
                font-weight: 800;
                margin: 5px;
                margin-top: 50px;
            }
            a { color: $primary; }
        }
    }

}

.blockchain-modal {
    section#header {
        // background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 67.19%, rgba(255, 255, 255, 0.47) 76.56%, #FFFFFF 93.75%), linear-gradient(180deg, #EEE1BE 0%, #CEC2A1 11.98%, #B9A66D 77.6%), #B8BF23;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 67.19%, rgba(255, 255, 255, 0.47) 76.56%, #FFFFFF 93.75%), linear-gradient(180deg, #F1DEA9 0%, #DAC690 11.98%, #C7AE67 77.6%), #B9A66D;
        text-align: center;
        h1 { 
            color: #fff !important;
            padding-top: 50px;
        }
        img {
            margin: 20px 0;
            margin-bottom: 100px;
            width: 80%;
        }
    }
    section.rounded-page {
        position: relative;
        top: -80px;
        margin-bottom: -80px;

        p#main-content { 
            font-size: 0.9em; 
            em { 
                color: $primary; 
                font-style: normal;
            }
        }
        h1 { 
            padding-top: 30px; 
            text-align: center;
            color: $primary;
        }

        #detail {
            margin-top: 30px;
            div:nth-child(odd) { text-align: center; }
            div:last-child { color: #23B6BF; }
            div:nth-child(4n - 2) { font-weight: 800; }
            .MuiTypography-body1 { font-family: inherit; }
        }
        #download {
            margin-top: 40px;
            text-align: center;

            img { width: 15vw; }
        }
    }
}

.more-text-modal {
    section#header {
        .image {
            position: relative;
            height: 40vh;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }

    section#body {
        h1 {
            font-size: 1.7em;
            text-align: center;
            margin-top: 0px;
            margin-bottom: 5px;
            color: $primary;
        }

        em {
            color: $primary;
            font-style: normal;
        }

    }
}