#certification-page {
    margin-bottom: -80px;

    h1 { 
        color: $primary; 
        font-weight: 800;
        font-size: 1.8em;
    }

    section#header {
        // background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 67.19%, rgba(255, 255, 255, 0.47) 76.56%, #FFFFFF 93.75%), linear-gradient(180deg, #EEE1BE 0%, $secondary 11.98%, $primary 77.6%), #B8BF23;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 67.19%, rgba(255, 255, 255, 0.47) 76.56%, #FFFFFF 93.75%), linear-gradient(180deg, #F1DEA9 0%, #DAC690 11.98%, #C7AE67 77.6%), #B9A66D;
        display: flex;
        align-items: center;
        padding: 100px 0;

        #logo-circle { 
            width: 100%;
            position: relative;
            top: -40px;
            
            #circle {
                width: 60vw;
                height: 60vw;
                
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                
                background: #fff;
                box-shadow: 0px 11px 21px rgba(0, 0, 0, 0.11), inset 0px 10px 63px rgba(0, 0, 0, 0.21), inset -18px -18px 30px #FFFFFF;
                border-radius: 184.086px;
                
                img { 
                    width: 40vw; 
                    
                    position: relative;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    section.rounded-page {
        position: relative;
        top: -80px;

        section#qualita {
            article#youtube-video {

                margin: 30px 0;

                iframe {
                    margin-top: 10px;
                    width: 100%;
                    height: auto;
                    box-shadow: 6px 6px 13px -3px rgba(0,0,0,0.63);
                    border-radius: 30px;
                }

                div {
                    font-size: 0.9em;
                    color: $primary;
                    padding: 15px 10px;
                }

                span { font-weight: bold; }

            }
        }

        section#certificazioni {

            section#cards {
                margin-top: 50px;

                article.card {
                    padding: 20px 30px;
                    text-align: center;
                
                    div { padding: 15px 0; }
                    p { 
                        text-align: left;
                    }
                    img { width: 35vw; }
                    
                    a {
                        font-weight: 800;
                        text-decoration: underline;
                        color: $primary;
                    }
                }
            }
            
        }
    }

}