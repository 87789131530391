.toast {
    width: 100%;
    div:first-child { margin-bottom: 10px; }
    div.link { margin: 10px 0; }
}

#product-page {
    margin-bottom: -80px;

    h2, h3, h4 { color: $primary; }

    section#header {
        // background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 67.19%, rgba(255, 255, 255, 0.47) 76.56%, #FFFFFF 93.75%), linear-gradient(180deg, #EEE1BE 0%, $secondary 11.98%, $primary 77.6%), #B8BF23;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 67.19%, rgba(255, 255, 255, 0.47) 76.56%, #FFFFFF 93.75%), linear-gradient(180deg, #F1DEA9 0%, #DAC690 11.98%, #C7AE67 77.6%), #B9A66D;
        padding-bottom: 100px;

        #felsineo-logo {
            img { width: 100%; }
        }

        h1#product-title {
            margin: 0;
            font-size: 1.8em;
            color: #fff;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }

        #product-image {
            position: relative;
            height: 90vw;
            width: 100%;

            img { 
                width: 45vw;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
    
            span#circle {
                $size: 80vw;
                position: absolute;
                top: 50%;
                left: 25%;
                transform: translateY(-50%);
                width: $size;
                height: $size;
                background: rgba(255, 255, 255, 0.85);
                box-shadow: 0px 11px 7px rgba(0, 0, 0, 0.11), inset 18px 18px 30px #D1D9E6, inset -18px -18px 30px #FFFFFF;
                border-radius: 1000px;
            }
        }

        .logo {
            img { width: 100%; }
        }

        article#description {
            margin-top: 40px;

            p {
                font-size: 0.8em;
                span { 
                    color: #fff;
                    font-weight: bold;
                } 
            }

        }

    }

    section.rounded-page {
        position: relative;
        top: -80px;

        article { margin-bottom: 30px; }

        article#accordion {
            margin-top: 50px;
            margin-bottom: 100px;

            .MuiAccordion-root::before { 
                background: none;
            }

            .MuiAccordionDetails-root {
                background-color: #B9A66D;
            }

            .MuiAccordionSummary-content {
                margin-left: calc(32px - 16px);

                // Icon and text alignment
                p { 
                    display: inline-flex;
                    align-items: center;
                    font-size: 0.9em;
                    img { 
                        margin-right: 20px; 
                        width: 2em;
                    }
                }
            }

            .MuiAccordionSummary-expandIcon {
                margin-right: calc(32px - 16px - 12px);
            }

            table#ingredienti {
                width: 100%;

                tr {
                    td { padding: 2px 0; }
                    td:nth-child(2) { 
                        width: 20%; 
                        text-align: center;
                    }
                }

                tfoot {
                    tr td { font-size: 0.8em; }
                    tr:first-child td {
                        padding-top: 30px
                    }
                }
            }

            table#valori-nutrizionali {
                width: 100%;
                
                tr {
                    td { padding: 2px 0; }
                    td:nth-child(2) {
                        width: 50%;
                        text-align: center;
                    }
                }
            }

            div#caratteristiche {
                .item {
                    margin: 10px 0;
                    .title, .value { margin: 2px 0; }
                    .title { color: #fff; }
                }
            }

        }

        article#plastica {
            margin-top: 50px;
            margin-bottom: 80px;

            a {
                color: $primary;
                text-decoration: none;
                font-weight: bold;
            }

            small {  font-size: 0.7em; }

            #product-image {
                position: relative;
                text-align: right;
                
                img { 
                    width: 100%; 
                    position: relative;
                    z-index: 1;
                }

                span.circle {
                    $size: 55vw;
                    position: absolute;
                    width: $size;
                    height: $size;
                    left: -27vw;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 0;
                    background: #fff;
                    // background: linear-gradient(180deg, rgba(255, 255, 255, 0.79) 16.15%, rgba(  184, 191, 35, 0.79) 89.58%), #B8BF23;
                    background: linear-gradient(180deg, #EEE1BE 24.48%, #B9A66D 100%), #B9A66D;
                    border-radius: 100%;
                    box-shadow: 0px 11px 21px rgba(0, 0, 0, 0.24);
                }
            }
        }

        article#sciccosa-line {
            margin-top: 80px;

            h3 { 
                text-align: left; 
                margin-bottom: 30px;
            }

            div {
                text-align: center;
                font-size: 0.95em;
                // font-weight: 800;
                // color: #3c4858;
            }

            img { 
                width: 100%; 
                margin-bottom: 10px;
            }
        }
        article#kmrosa-line {
            margin-top: 80px;
            h3 { 
                text-align: left; 
                margin-bottom: 30px;
            }
            div {
                text-align: center;
                font-size: 0.95em;
                // font-weight: 800;
                // color: #3c4858;
            }
            img { 
                width: 100%; 
                margin-bottom: 10px;
            }
        }
        article#loghi {
            margin-top: 80px;

            text-align: center;
            .logo {
                img { margin: 10px; }
    
                .title, .subtitle { 
                    margin-bottom: 5px;
                    color: $primary;
                    line-height: 15px; 
                }
                
                .title { 
                    font-weight: bold;
                    font-size: 0.8em;
                }
                
                .subtitle { font-size: 0.7em; }
            }
        }

        article#varianti {
            margin-top: 80px;
            margin-bottom: 80px;
            a { text-decoration: none; }
        }
    
        .image-slider {
            list-style: none;
            display: flex;
            flex-wrap: nowrap;
            overflow-y: auto;
            padding: 0;

            margin-top: 50px;

            li {
                margin: 0 15px;
                text-align: center;

                img { height: 40vw; }

                p {
                    font-size: 0.8em;
                    font-weight: 800;
                    color: #3c4858;
                    margin-top: 5px;
                }
            }

            li:first-child { margin-left: 32px; }
            li:last-child { margin-right: 32px; }
        }
    }

}

.plastica-modal {
    .MuiDialog-paper {
        background-color: rgba(0,0,0,0.5);
    }

    #content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -60%);

        box-sizing: border-box;
        width: 90vw;
        min-height: 60vh;
        max-height: 70vh;
        padding: 20px 0;

        background: linear-gradient(0deg, rgba(185, 166, 109, 0.15), rgba(185, 166, 109, 0.15)), linear-gradient(180deg, #FAF1D9 0%, #B9A66D 100%);
        border-radius: 57px;
        color: #fff;

        div:first-child {
            height: 30vh;
            img { height: 100%; }
        }

        p { font-size: 0.9em; }
    }
}