@import '_include-media.scss';

//--- MEDIA QUERIES -------------------------------

// If you want to define custom breakpoints
// $breakpoints: (small: 320px, medium: 768px, large: 1024px);

// Test media query
// @include media(">phone") {
//     body {
//         margin: 100px;
//     }
// }

//--- COLORS ---------------------------------------

$primary: #B9A66D;
$secondary: #CEC2A1;

.primary-color { color: $primary !important; }

//--- FONT ----------------------------------------

@mixin open-sans-font {
    font-family: 'Open Sans', sans-serif !important;
}

.bold-text { font-weight: bold; }
.center-text { text-align: center; }

//--- SHARED --------------------------------------

body { 
    margin: 0;
    @include open-sans-font;
}

div#root {
    overflow-x: hidden;
    overscroll-behavior: none;
}

.rounded-page {
    padding-top: 30px;
    // background: rgba(255, 255, 255, 0.56);
    background-color: #fff;
    box-shadow: 0px -24px 47px rgba(0, 0, 0, 0.09);
    border-radius: 45px 45px 0 0;

    .title-page { margin-top: 0; }
}

#filled-weight-helper-text{
    font-size: xx-small
}

button.custom-button {
    min-width: 116px;
    min-height: 40px;
    
    background: linear-gradient(180deg, rgba(238, 225, 190, 0.8) 10.94%, rgba(185, 166, 109, 0.8) 75%), #B9A66D;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 34px;
    border: 0;
    
    color: #fff;
    font-size: 1em;
    padding: 15px;
}

button.custom-button:disabled { 
    background: linear-gradient(180deg, rgba(208,208,208,1) 11%, rgba(148,148,148,1) 75%, rgba(148,148,148,1) 100%);
}

//--- CUSTOMIZE MATERIAL UI ---------------------------------------

.MuiContainer-root { padding: 0 32px !important; }

.MuiSnackbar-anchorOriginBottomCenter { bottom: 0 !important; }
.MuiSnackbar-root {
    left: 0 !important;
    right: 0 !important;
    .MuiSnackbarContent-root {
        color: #000 !important;
        background-color: #fff !important;
        border-radius: 34px 34px 0 0 !important;
        .MuiSnackbarContent-action { 
            margin-right: 0 !important;
            margin-left: 0 !important;
            padding: 0 10px !important;
            width: 100% !important;
            font-size: 1.1em !important;
            a { color: $primary; }
        }
    }
}

//--- PAGES ---------------------------------------

@import "_product-page.scss";
@import "_company-page.scss";
@import "_certification-page.scss";
@import "_traceability-page.scss";
