#ricette-slider {

    display: flex;
    flex-wrap: nowrap;
    overflow-y: scroll;

    .ricetta-box {
        $box-size: 70vw;
        $page-margin: 32px;

        position: relative;
        height: $box-size;
        width: $box-size;
        margin: 20px;

        filter: drop-shadow(7px 7px 10px rgba(0, 0, 0, 0.18));

        &:first-child { margin-left: $page-margin; }
        &:last-child { margin-right: $page-margin; }

        .mask, .image { border-radius: 36px; }

        .mask {
            position: relative;
            width: $box-size;
            height: $box-size;
        
            z-index: 1;
        
            background: linear-gradient(0deg, rgba(185, 166, 109, 0.88) 0%, rgba(238, 225, 190, 0.5016) 45.31%, rgba(209, 217, 230, 0.1936) 66.67%, rgba(51, 51, 51, 0) 93.23%);
            opacity: 0.9;
        }
        
        .image {
            position: absolute;
            top: 0;
            z-index: 0;
            width: $box-size;
            height: $box-size;
            background-size: cover;
        }
        
        p {
            position: absolute;
            bottom: 10%;
            left: 50%;
            transform: translateX(-50%);
        
            z-index: 2;
        
            width: $box-size;
            margin: 0;
        
            color: #fff;
            text-shadow: 2px 2px 5px rgba(0,0,0,0.61);
            font-size: 1.3em;
            font-weight: 400;
            text-align: center;
        }
    }

}
