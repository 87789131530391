@mixin logo-circle($size) {
    .logo-circle {
        width: 100%;
        height: $size;

        margin: 10px 0;
        position: relative;

        .circle {
            width: $size;
            height: $size;

            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            
            background: rgba(230, 233, 239, 0.6);
            box-shadow: inset 18px 18px 30px rgba(209, 217, 230, 0.6), inset -18px -18px 30px rgba(255, 255, 255, 0.6);
            border-radius: 100%;
        }

        img {
            width: $size - 10;
            height: auto;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

#company-page {
    margin-bottom: -80px;
    
    section#header {
        height: 65vh;
        background-image: url(../../assets/img/company-page/copertina-azienda.jpg);
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;
    }

    section.rounded-page {
        position: relative;
        top: -90px;

        h1 { 
            color: $primary;
            font-size: 2em;
            font-weight: bold;
        }
    
        article { margin-bottom: 50px; }
        
        article#marchio-storico {
            @include logo-circle(60vw);
        }

        article#numeri {
            // background-color: $secondary;
            background: linear-gradient(180deg, #E2CF9B 0%, #B9A66D 100%);
            border-radius: 34px;

            padding: 20px;

            color: #fff;
            text-align: center;
            
            @include logo-circle(50vw);
            
            ul {
                padding: 0;
                list-style: none;

                li { 
                    margin: 30px 0; 

                    h1, h3 { 
                        margin: 0; 
                        color: #fff;
                    }
                    h3 { font-weight: 400; }
                    span { font-size: 0.8em; }
                }
            }
        }

    
        article#contact {
            margin-top: 60px;

            h1 { text-align: center; }

            iframe {
                height: 200px;
                width: 100%;
                border: 0;
                border-radius: 34px;
                margin-top: 20px;
                margin-bottom: 40px;
            }

            #detail {
                font-size: 0.8em;
                margin: 20px 0;

                div { padding: 10px; }

                div:nth-of-type(odd) { text-align: center; }
        
                a {
                    color: #000;
                    text-decoration: underline;
                }
            }
        }
    }


}